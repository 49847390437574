<template>
  <div class="login">
   <hNav/>
    <div class="content">

    <div class="main">
    <div class="mylogin" align="center">
      <h4>登录</h4>
      <el-form :model="loginForm" :rules="loginRules" ref="loginForm" label-width="0px">
        <el-form-item label="" prop="account" style="margin-top: 10px">
          <el-row>
            <el-col :span="24">
              <el-input class="inps" placeholder="账号" v-model="loginForm.account">
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="passWord">
          <el-row>
            <el-col :span="24">
              <el-input class="inps" type="password" placeholder="密码" v-model="loginForm.passWord"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 55px">
          <el-button type="primary" round class="submitBtn" @click="submitForm">登录
          </el-button>
        </el-form-item>
        <div class="unlogin">
          <router-link :to="{ path: '/register' }">
            <a href="register.vue" target="_blank" align="right">免费注册</a>
          </router-link>
          |
          <router-link :to="{ path: '/forgetpwd' }"> 忘记密码? </router-link>
        </div>
      </el-form>
    </div>
    </div>
    </div>
    <bNav/>
  </div>
</template>


<script>
  import hNav from './components/hnav.vue'
  import bNav from './components/bnav.vue'
  import {
    mapMutations
  } from "vuex";

  export default {
    components: {
      hNav,
      bNav,
    },
    name: "Login",
    data: function() {
      return {
        loginForm: {
          account: "",
          passWord: "",
        },
        loginRules: {
          account: [{
            required: true,
            message: "请输入账号",
            trigger: "blur"
          }],
          passWord: [{
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }],
        },
      };
    },

    methods: {
      ...mapMutations(["changeLogin"]),
      submitForm() {
        const userAccount = this.loginForm.account;
        const userPassword = this.loginForm.passWord;
        if (!userAccount) {
          return this.$message({
            type: "error",
            message: "账号不能为空！",
          });
        }
        if (!userPassword) {
          return this.$message({
            type: "error",
            message: "密码不能为空！",
          });
        }
        console.log("用户输入的账号为：", userAccount);
        console.log("用户输入的密码为：", userPassword);

      },
    },
  };
</script>

<style lang="less" scoped>
  .login {
    width: 100vw;
    padding: 0;
    margin: 0;
    height: 100vh;
    font-size: 16px;
    background-position: left top;
    // background-color: #242645;
    color: #fff;
    font-family: "Source Sans Pro";
    position: relative;
  }

  .mylogin {
    width: 380px;
    height: 358px;
    position: absolute;
    top: 105px;
    right: 24px;
    margin: auto;
    padding: 40px 40px 40px 40px;
    // box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
    opacity: 1;
    // background: linear-gradient(230deg,
    //     rgba(53, 57, 74, 0) 0%,
    //     rgb(0, 0, 0) 100%);
    background: #fff;
    border-radius: 20px;
    h4{
      font-size: 20px;
      color: #000;
      margin-bottom: 20px;
    }
  }

  .inps input {
    border: none;
    color: #fff;
    background-color: transparent;
    font-size: 12px;
  }
.unlogin{

  color: #000;
  font-size: 14px;
  a{
   color: #39f;
  }
}
  .submitBtn {
    background-color: transparent;
    color: #fff;
    width: 100%;
    background-color:#39f ;
  }
  .content{
    background: url('../../assets/images/n_login_bg.png') no-repeat center top;
     height: 600px;
     min-width: 1180px;
     margin-top: -10px;
  }
  .main{
      width: 1180px;
      margin: auto;
      font-size: 12px;
      text-align: left;
      position: relative;

  }

</style>
